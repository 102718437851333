import React  from 'react';
import { ReferenceField, NumberField, TextField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `group_faq_positions/create`,
          state: { record: omitId(record) }
        }}/>);
};

const GroupFaqPositionList = (props) => (
    <ListGuesser {...props}>
      <NumberField source="position" />
      <ReferenceField label="Faq" source="faq" reference="faqs">
        <TextField source="title" />
      </ReferenceField>
      <CloneElement />
    </ListGuesser>
);

export default GroupFaqPositionList;