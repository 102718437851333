import React  from 'react';
import { EditGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput, NumberInput } from 'react-admin';

const GroupStepPositionEdit = (props) => (
  <EditGuesser {...props}>
    <NumberInput defaultValue="1" source="position" />
    <ReferenceInput
      label="Step"
      source="step"
      reference="steps"
      perPage={2000}
    >
      <AutocompleteInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
      />
    </ReferenceInput>
  </EditGuesser>
  );

export default GroupStepPositionEdit;