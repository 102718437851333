import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const GroupStepPositionCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="position" />
    <ReferenceInput
      label="Step"
      source="step"
      reference="steps"
      perPage={2000}
    >
      <AutocompleteInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
      />
    </ReferenceInput>
  </CreateGuesser>
  );

export default GroupStepPositionCreate;