import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, SelectInput, AutocompleteArrayInput } from 'react-admin';
import { RichTextInput } from '@agilie/ra-tinymce-input';

const choices = [
  { id: 'quantity', full_name: 'Quantity' },
  { id: 'format', full_name: 'Formats' },
  { id: 'upload', full_name: 'Upload' },
  { id: 'switch', full_name: 'Switch' },
  { id: 'orientation', full_name: 'Orientation' },
  { id: 'body', full_name: 'Body' },
  { id: 'background', full_name: 'Background' },
  { id: 'custom-text', full_name: 'Custom Text' },
  { id: 'note', full_name: 'Note' },
  { id: 'select', full_name: 'Select' },
];

const StepTypeEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <SelectInput source="type" choices={choices} optionText="full_name" optionValue="id" />
    <ReferenceArrayInput
      label="Step Type Values"
      source="stepTypeValues"
      reference="step_type_values"
      perPage={100}
      sort={{field: 'id', order: 'DESC'}}
    >
      <AutocompleteArrayInput
        suggestionLimit={7}
        optionText="title" 
        shouldRenderSuggestions={(value) => value.trim().length >= 4}
      />
    </ReferenceArrayInput>
    <p>Generated Text:</p>
    <RichTextInput label="Generated Text" source="generatedText"
      apiKey='kajmvp4egjobgynq3phsfqif168so5skv2apq56c5zmjum5a'
      init={{
          height: 500,
          menubar: true,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
  </EditGuesser>
  );

export default StepTypeEdit;