import React  from 'react';
import { ReferenceField, TextField, useRecordContext, ReferenceArrayField, SingleFieldList, ChipField, DateField, FunctionField, BooleanField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";
import { Rating } from 'react-simple-star-rating'
import { useGetOne } from 'react-admin';

const entrypoint = "https://d1wqdzlxqamchi.cloudfront.net/"

const RatingValue = ({ source }) => {
  const record = useRecordContext();
  return (<Rating size={20} ratingValue={record.rating * 20} readonly />);
};

const File = ({ record }) => {
  const { data, loading } = useGetOne('files', record);
  if (loading) { return "Loading..."; }
  if (data) {
    return (
      <img
        alt={data.alt}
        src={`https://cdnserver.cartoonely.com/upload/w_200,h_200,q_40/${encodeURI(`${entrypoint}media/${data.filePath}`)}`}
        width={100}
      />
    );
  }
};

const FilenameField = props => (
  <FunctionField 
    {...props}
    render={record => {    
      return (
        record.files.map((file) => {
          return (
            <div>
              <File record={file} />
            </div>
          )
        }
      ))
    }}
  />
);

const ReviewList = (props) => (
    <ListGuesser {...props}>
      <FilenameField />
      <ReferenceField label="Last name" source="user" reference="users">
        <TextField source="lastName" />
      </ReferenceField>
      <ReferenceField label="First name" source="user" reference="users">
        <TextField source="firstName" />
      </ReferenceField>
      <TextField source="message" />
      <><RatingValue source="rating" /></>
      <ReferenceArrayField
        label="Products"
        reference="products"
        source="products"
      >
        <SingleFieldList>
            <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <BooleanField source="enabled" />
    </ListGuesser>
  );

export default ReviewList;