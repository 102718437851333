import React  from 'react';
import { ReferenceField, TextField, BooleanField, CloneButton, DateField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `projects/create`,
          state: { record: omitId(record) }
        }}/>);
};

const ProjectList = (props) => (
    <ListGuesser {...props}>
      <TextField source="uuid" />
      <TextField source="reference" />
      <ReferenceField label="Last name" source="user" reference="users">
        <TextField source="lastName" />
      </ReferenceField>
      <ReferenceField label="First name" source="user" reference="users">
        <TextField source="firstName" />
      </ReferenceField>
      <ReferenceField label="Company name" source="user" reference="users">
        <TextField source="companyName" />
      </ReferenceField>
      <TextField source="quantity" />
      <TextField source="totalPrice" />
      <ReferenceField label="Currency" source="currency" reference="currencies">
        <TextField source="code" />
      </ReferenceField>
      <TextField source="status" />
      <TextField source="step" />
      <TextField source="email" />
      <BooleanField source="payment" />
      <BooleanField source="useTreeNation" />
      <BooleanField source="useLicenceCommercial" />
      <BooleanField source="useSources" />
      <TextField source="shippingDest" />
      <TextField source="coupon" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <CloneElement />
    </ListGuesser>
);

export default ProjectList;