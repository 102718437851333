import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput, FileField, FileInput } from 'react-admin';

const FileEdit = (props) => (
  <EditGuesser {...props}>
    <ReferenceInput
      source="language"
      reference="languages"
      label="Language"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <FileInput source="file" label="Choose a local file">
        <FileField source="file" title="title" />
    </FileInput>
    <InputGuesser source="title" />
    <InputGuesser source="alt" />
    <InputGuesser source="cloud" />
  </EditGuesser>
  );

export default FileEdit;