import React  from 'react';
import { ReferenceField, TextField, DateField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const PaymentList = (props) => (
    <ListGuesser {...props}>
      <TextField source="amount" />
      <TextField source="keyStripeCharge" />
      <ReferenceField label="Last name" source="user" reference="users">
        <TextField source="lastName" />
      </ReferenceField>
      <ReferenceField label="First name" source="user" reference="users">
        <TextField source="firstName" />
      </ReferenceField>
      <TextField source="currencyCode" />
      <ReferenceField label="Order" source="order" reference="orders">
        <TextField source="reference" />
      </ReferenceField>
      <TextField source="status" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </ListGuesser>
);

export default PaymentList;