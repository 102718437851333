import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const GroupFaqPositionCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="position" />
    <ReferenceInput
      label="Faq"
      source="faq"
      reference="faqs"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
  </CreateGuesser>
  );

export default GroupFaqPositionCreate;