import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

const StepTypeValueRulesCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="title" />
    <ReferenceArrayInput
      label="Name rules"
      source="typeValueIntl"
      reference="step_type_value_intls"
      perPage={1900}
    >
      <AutocompleteArrayInput optionText="title" />
    </ReferenceArrayInput>
  </CreateGuesser>
  );

export default StepTypeValueRulesCreate;