import React  from 'react';
import { ReferenceField, TextField, BooleanField, NumberField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `steps/create`,
          state: { record: omitId(record) }
        }}/>);
};

const StepList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="categoryName" />
      <TextField source="title" />
      <TextField source="description_required" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Type"
        source="stepType"
        reference="step_types"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="position" />
      <BooleanField defa source="upsell" />
      <BooleanField defa source="required" />
      <BooleanField source="enabled" />
      <CloneElement />
    </ListGuesser>
);

export default StepList;