import React  from 'react';
import { ReferenceField, TextField, BooleanField, FunctionField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const entrypoint = "https://d1wqdzlxqamchi.cloudfront.net/"

const FilenameField = props => (
    <FunctionField 
      {...props}
      render={record => {
        var ext =  record.filePath.split('.').pop(); 

        if (ext === "jpg" || ext === "png" ||  ext === "jpeg" || ext === "webp") {
          return (
            <img
              alt={record.alt}
              src={`https://cdnserver.cartoonely.com/upload/w_200,h_200,q_40/${encodeURI(`${entrypoint}media/${record.filePath}`)}`}
              width={100}
            />
          )
        }

        if (ext === "mpeg" || ext === "avi" ||  ext === "mp4") {
          return (
            <video autoplay muted loop controls width="100">
              <source src={`${entrypoint}media/${record.filePath}`}
                      type={`video/${ext}`} />
              Sorry, your browser doesn't support embedded videos.
            </video>
          )
        }

        if (ext === "pdf") {
          return (
            <iframe title={record.alt} src={`${entrypoint}media/${record.filePath}`} width="100"></iframe>
          )
        }

        if (ext === "zip") {
          return (
            <a href={`${entrypoint}media/${record.filePath}`} title={record.alt} >Download ZIP</a>
          )
        }
      }}
    />
);

const FileList = (props) => (
    <ListGuesser {...props}>
      <FilenameField />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="title" />
      <TextField source="alt" />
      <BooleanField source="cloud" />
      <ReferenceField label="User FirstName" source="owner" reference="users">
        <TextField source="firstName" />
      </ReferenceField>
      <ReferenceField label="User LastName" source="owner" reference="users">
        <TextField source="lastName" />
      </ReferenceField>
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
    </ListGuesser>
  );

export default FileList;