import React  from 'react';
import { TextField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const FaqAnswerList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="title" />
    </ListGuesser>
  );

export default FaqAnswerList;