import React  from 'react';
import { ReferenceField, ReferenceArrayField, SingleFieldList, ChipField, TextField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `order_products/create`,
          state: { record: omitId(record) }
        }}/>);
};

const OrderProductList = (props) => (
    <ListGuesser {...props}>
      <TextField source="quantity" />
      <ReferenceField label="Product" source="product" reference="products">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField
        label="Product step value"
        reference="order_product_steps"
        source="orderProductStep"
      >
        <SingleFieldList>
            <ChipField source="value" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Order" source="orders" reference="orders">
        <SingleFieldList>
            <ChipField source="reference" />
        </SingleFieldList>
      </ReferenceArrayField>
      <CloneElement />
    </ListGuesser>
);

export default OrderProductList;