import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import InputAdornment from '@material-ui/core/InputAdornment';

const OffersEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="code" />
    <InputGuesser source="amount" />
    <InputGuesser
      source="percent"
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
      }}
    />
    <InputGuesser source="enabled" />
  </EditGuesser>
  );

export default OffersEdit;