import React  from 'react';
import { useGetList, Loading  } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const ProjectDeadline = () => {
    const { data, isLoading, error } = useGetList(
        'order_artists/projects-deadline',
        { 
            pagination: { page: 1, perPage: 10 },
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
                <Card sx={{ maxWidth: 275 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 53 }} gutterBottom>
                        Project Deadline
                    </Typography>
                    {data && data.artist && (
                        <Typography variant="body2">
                            <ul>
                                {data.artist.map(({ id, companyName, firstName, lastName }) =>
                                    <li key={id}>{companyName ? companyName : `${firstName} ${lastName}`}</li>
                                )}
                            </ul>
                        </Typography>
                    )}
                </CardContent>
            </Card>
    );
};

export default ProjectDeadline;