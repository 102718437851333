import React  from 'react';
import { ReferenceField, TextField, DateField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CurrencyList = (props) => (
    <ListGuesser {...props}>
      <TextField source="code" />
      <TextField source="rates" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="updatedAt" />
    </ListGuesser>
  );

export default CurrencyList;