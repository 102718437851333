import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { AutocompleteInput, ArrayInput, SimpleFormIterator, DateInput, ReferenceArrayInput, SelectArrayInput, BooleanInput, ReferenceInput, SelectInput } from 'react-admin';

const choices = [
  { id: "ROLE_USER", full_name: 'User' },
  { id: "ROLE_ADMIN", full_name: 'Admin' },
  { id: "ROLE_ARTIST", full_name: 'Artist' },
];

const UserCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="email" />
    <InputGuesser source="username" />
    <InputGuesser source="firstName" />
    <InputGuesser source="lastName" />
    <ReferenceInput
      label="Avatar"
      source="avatar"
      reference="files"
      perPage={10000}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <ArrayInput source="roles">
      <SimpleFormIterator>
        <SelectInput source="roles" choices={choices} optionText="full_name" />
      </SimpleFormIterator>
    </ArrayInput>
    <InputGuesser source="password" />
    <InputGuesser source="phone" />
    <DateInput source="dateOfBirth" />
    <ReferenceInput label="Language" source="language" reference="languages">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceArrayInput label="Addresses" reference="addresses" source="address">
      <SelectArrayInput source="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput label="Reviews" reference="reviews" source="reviews">
      <SelectArrayInput optionText={reviews => `${reviews.message.substring(0,10) + '...'}`} />
    </ReferenceArrayInput>
    <BooleanInput source="enabled" defaultValue={true} />
    <BooleanInput source="isBtob" defaultValue={false} />
  </CreateGuesser>
  );

export default UserCreate;