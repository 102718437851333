import * as React from "react";
import OrdersByStatus from "./Dashboard/OrdersByStatus";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const Dashboard = (props) => {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <OrdersByStatus label="Abandoned cart" status="ABANDONED_CART" widthList={false} />
                </Grid>
                <Grid item xs={3}>
                    <OrdersByStatus label="Pending" status="PENDING" widthList={false} />
                </Grid>
                <Grid item xs={3}>
                    <OrdersByStatus label="In Progress" status="IN_PROGRESS" widthList={false} />
                </Grid>
                <Grid item xs={3}>
                    <OrdersByStatus label="Validated" status="VALIDATED" widthList={false} />
                </Grid>
            </Grid>
        </Container>
    )
};

  export default Dashboard;