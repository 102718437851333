import React  from 'react';
import { ReferenceField, TextField, CloneButton, DateField, useRecordContext } from "react-admin";
import { ListGuesser } from "@api-platform/admin";
import { Rating } from 'react-simple-star-rating'

const RatingValue = ({ source }) => {
  const record = useRecordContext();
  return (<Rating size={20} ratingValue={record.rating * 20} readonly />);
};

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `order_artists/create`,
          state: { record: omitId(record) }
        }}/>);
};

const OrderArtistList = (props) => (
    <ListGuesser {...props}>
      <TextField source="id" />
      <ReferenceField label="Order" source="order" reference="orders">
        <TextField source="reference" />
      </ReferenceField>
      <ReferenceField label="Product" source="orderProduct" reference="order_products">
        <ReferenceField label="Product" source="product" reference="products">
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <TextField source="deadline" />
      <TextField source="status" />
      <TextField source="statusCustomer" />
      <ReferenceField label="Artist" source="artist" reference="artists">
        <ReferenceField label="Artist" source="user" reference="users">
          <TextField source="firstName" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField label="Artist" source="artist" reference="artists">
        <ReferenceField label="Artist" source="user" reference="users">
          <TextField source="lastName" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField label="Review" source="review" reference="reviews">
      <><RatingValue source="rating" /></>
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <DateField source="validatedAt" />
      <DateField source="cancelledAt" />
      <CloneElement />
    </ListGuesser>
);

export default OrderArtistList;