import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

const StepFormatAttributeEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <ReferenceArrayInput
      label="Step Formats Values"
      source="stepFormatValues"
      reference="step_format_values"
      page={1}
      perPage={1900}
    >
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>
  </EditGuesser>
  );

export default StepFormatAttributeEdit;