import React  from 'react';
import { TextField, BooleanField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const LanguageList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="code" />
      <BooleanField source="enabled" />
    </ListGuesser>
  );

export default LanguageList;