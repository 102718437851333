import React  from 'react';
import { EditGuesser } from "@api-platform/admin";
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const PriceIntlEdit = (props) => (
  <EditGuesser {...props}>
    <Typography variant="h4" gutterBottom component="div">
      Edit price intl
    </Typography>

    <TextInput source="price" />
    <TextInput source="priceSale" />
    <ReferenceInput
        source="currency"
        reference="currencies"
        label="Currency"
        filterToQuery={searchText => ({ code: searchText })}
      >
        <AutocompleteInput optionText="code" />
    </ReferenceInput>
  </EditGuesser>
  );

export default PriceIntlEdit;