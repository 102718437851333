import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

const StepTypeValueRulesEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="title" />
    <ReferenceArrayInput
      label="Name rules"
      source="typeValueIntl"
      reference="step_type_value_intls"
      perPage={1900}
    >
      <AutocompleteArrayInput optionText="title" />
    </ReferenceArrayInput>
  </EditGuesser>
  );

export default StepTypeValueRulesEdit;