import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

const StepFormatAttributeCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <ReferenceArrayInput
      label="Step Formats Values"
      source="stepFormatValues"
      reference="step_format_values"
      page={1}
      perPage={1900}
    >
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>
  </CreateGuesser>
  );

export default StepFormatAttributeCreate;