import React from "react";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import { Login } from "react-admin";
import CartoonelyTheme from "./Theme/Theme";
import CartoonelyLayout from "./Theme/Layout";
import ArtistList from "./Artist/List";
import CategoryList from "./Category/List";
import SubCategoryList from "./SubCategory/List";
import FileEdit from "./File/Edit";
import FileCreate from "./File/Create";
import FileList from "./File/List";
import UserList from "./User/List";
import ProductList from "./Product/List";
import Dashboard from "./dashboard";
import CategoryEdit from "./Category/Edit";
import SubCategoryEdit from "./SubCategory/Edit";
import CurrencyList from "./Currency/List";
import LanguageList from "./Language/List";
import ProductEdit from "./Product/Edit";
import ProductCreate from "./Product/Create";
import CategoryCreate from "./Category/Create";
import SubCategoryCreate from "./SubCategory/Create";
import ReviewList from "./Review/List";
import StepList from "./Step/List";
import CollectionList from "./Collection/List";
import StepFormatList from "./Step/Format/List";
import StepFormatAttributeList from "./Step/Format/Attribute/List";
import StepFormatValueList from "./Step/Format/Value/List";
import StepTypeList from "./Step/Type/List";
import StepTypeValueList from "./Step/Type/Value/List";
import OrderList from "./Order/List";
import OrderArtistList from "./Order/Artist/List";
import PersonIcon from '@material-ui/icons/Person';
import Chatcon from '@material-ui/icons/ChatBubble';
import Starcon from '@material-ui/icons/Star';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CategoryIcon from '@material-ui/icons/Category';
import SettingsIcon from '@material-ui/icons/Settings';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ImageIcon from '@material-ui/icons/Image';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LanguageIcon from '@material-ui/icons/Language';
import TagIcon from '@material-ui/icons/TagFaces';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import OrderProductList from "./Order/Product/List";
import OrderProductStepList from "./Order/Product/Step/List";
import TaxList from "./Tax/List";
import TagList from "./Tag/List";
import PriceRulesList from "./Product/PriceRules/List";
import PriceRulesEdit from "./Product/PriceRules/Edit";
import PriceRulesCreate from "./Product/PriceRules/Create";
import PriceIntlList from "./Product/PriceIntl/List";
import PriceIntlEdit from "./Product/PriceIntl/Edit";
import PriceIntlCreate from "./Product/PriceIntl/Create";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import GroupStepList from "./GroupStep/List";
import GroupStepPositionList from "./GroupStep/Position/List";
import BlogList from "./Blog/List";
import BlogEdit from "./Blog/Edit";
import BlogCreate from "./Blog/Create";
import BlogCategoryCreate from "./Blog/Category/Create";
import BlogCategoryList from "./Blog/Category/List";
import BlogCategoryEdit from "./Blog/Category/Edit";
import FaqList from "./Faq/List";
import FaqEdit from "./Faq/Edit";
import FaqCreate from "./Faq/Create";
import FaqAnswerList from "./Faq/Answer/List";
import FaqAnswerEdit from "./Faq/Answer/Edit";
import FaqAnswerCreate from "./Faq/Answer/Create";
import UserEdit from "./User/Edit";
import UserCreate from "./User/Create";
import GroupStepCreate from "./GroupStep/Create";
import GroupStepEdit from "./GroupStep/Edit";
import GroupStepPositionEdit from "./GroupStep/Position/Edit";
import GroupStepPositionCreate from "./GroupStep/Position/Create";
import StepEdit from "./Step/Edit";
import StepCreate from "./Step/Create";
import StepTypeEdit from "./Step/Type/Edit";
import StepTypeCreate from "./Step/Type/Create";
import StepTypeValueEdit from "./Step/Type/Value/Edit";
import StepTypeValueCreate from "./Step/Type/Value/Create";
import StepFormatEdit from "./Step/Format/Edit";
import StepFormatCreate from "./Step/Format/Create";
import StepFormatAttributeEdit from "./Step/Format/Attribute/Edit";
import StepFormatAttributeCreate from "./Step/Format/Attribute/Create";
import StepFormatValueEdit from "./Step/Format/Value/Edit";
import StepFormatValueCreate from "./Step/Format/Value/Create";
import OrderCreate from "./Order/Create";
import OrderEdit from "./Order/Edit";
import StepTypeValueRulesList from "./Step/Type/Rules/List";
import StepTypeValueRulesEdit from "./Step/Type/Rules/Edit";
import StepTypeValueRulesCreate from "./Step/Type/Rules/Create";
import StepTypeValueIntlList from "./Step/Type/Intl/List";
import StepTypeValueIntlEdit from "./Step/Type/Intl/Edit";
import StepTypeValueIntlCreate from "./Step/Type/Intl/Create";
import TagEdit from "./Tag/Edit";
import TagCreate from "./Tag/Create";
import CollectionEdit from "./Collection/Edit";
import CollectionCreate from "./Collection/Create";
import GroupFaqList from "./GroupFaq/List";
import GroupFaqEdit from "./GroupFaq/Edit";
import GroupFaqCreate from "./GroupFaq/Create";
import GroupFaqPositionList from "./GroupFaq/Position/List";
import GroupFaqPositionEdit from "./GroupFaq/Position/Edit";
import GroupFaqPositionCreate from "./GroupFaq/Position/Create";
import GroupStepFormatList from "./GroupStepFormat/List";
import GroupStepFormatEdit from "./GroupStepFormat/Edit";
import GroupStepFormatCreate from "./GroupStepFormat/Create";
import GroupStepFormatPositionList from "./GroupStepFormat/Position/List";
import GroupStepFormatPositionEdit from "./GroupStepFormat/Position/Edit";
import GroupStepFormatPositionCreate from "./GroupStepFormat/Position/Create";
import MessengerList from "./Messenger/List";
import OffersList from "./Offers/List";
import OffersEdit from "./Offers/Edit";
import OffersCreate from "./Offers/Create";
import ShippingMethodList from "./ShippingMethod/List";
import ShippingMethodEdit from "./ShippingMethod/Edit";
import ShippingMethodCreate from "./ShippingMethod/Create";
import PortfolioList from "./Portfolio/List";
import PortfolioEdit from "./Portfolio/Edit";
import PortfolioCreate from "./Portfolio/Create";
import PortfolioCategoryList from "./Portfolio/Category/List";
import PortfolioCategoryEdit from "./Portfolio/Category/Edit";
import PortfolioCategoryCreate from "./Portfolio/Category/Create";
import PortfolioSubCategoryList from "./Portfolio/SubCategory/List";
import PortfolioSubCategoryEdit from "./Portfolio/SubCategory/Edit";
import PortfolioSubCategoryCreate from "./Portfolio/SubCategory/Create";
import customRoutes from "./customRoutes";
import OrderArtistReclaimPaymentList from "./Order/Artist/ReclaimPayment/List";
import ReviewEdit from "./Review/Edit";
import ReviewCreate from "./Review/Create";
import ArtistEdit from "./Artist/Edit";
import ArtistCreate from "./Artist/Create";
import CelebrityList from "./Celebrity/List";
import CelebrityEdit from "./Celebrity/Edit";
import CelebrityCreate from "./Celebrity/Create";
import PaymentList from "./Order/Payment/List";
import OrderArtistSubmitList from "./Order/Artist/Submit/List";
import OrderShow from "./Order/Show";
import FilesList from "./Product/Files/List";
import FilesEdit from "./Product/Files/Edit";
import FilesCreate from "./Product/Files/Create";
import ProjectList from "./Project/List";
import ProjectShow from "./Project/Show";
import ArtistExperienceList from "./Artist/Experience/List";
import ArtistExperienceEdit from "./Artist/Experience/Edit";
import ArtistExperienceCreate from "./Artist/Experience/Create";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const i18nProvider = polyglotI18nProvider((locale) => englishMessages, "en", {
  allowMissing: true,
  onMissingKey: (key, _, __) => key,
});

const App = () => (
    <HydraAdmin
        entrypoint={ entrypoint }
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        loginPage={ Login }
        theme={ CartoonelyTheme }
        layout={ CartoonelyLayout }
        dashboard={ Dashboard }
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
    >
      {/* Users */}
      <ResourceGuesser name="usersItems" icon={PersonIcon} options={{ "label": "Manage Users", "isMenuParent": true }} />
      <ResourceGuesser
        name="users"
        icon={PersonIcon}
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        options={{ "label": "Users", "menuParent": "usersItems" }}
      />
      <ResourceGuesser name="addresses" list={null} />
      <ResourceGuesser
        name="artists"
        icon={PersonIcon}
        list={ArtistList}
        edit={ArtistEdit}
        create={ArtistCreate}
        options={{ "label": "Artists", "menuParent": "usersItems" }}
      />
      <ResourceGuesser
        name="messengers"
        icon={Chatcon}
        list={MessengerList}
        options={{ "label": "Messenger", "menuParent": "usersItems" }}
      />
      <ResourceGuesser
        name="messages"
        icon={Chatcon}
        options={{ "label": "Messages", "menuParent": "usersItems" }}
      />
      <ResourceGuesser
        name="reviews"
        icon={Starcon}
        list={ReviewList}
        edit={ReviewEdit}
        create={ReviewCreate}
        options={{ "label": "Reviews", "menuParent": "usersItems" }}
      />
      <ResourceGuesser
        name="portfolios"
        icon={BusinessCenterIcon}
        list={PortfolioList}
        edit={PortfolioEdit}
        create={PortfolioCreate}
        options={{ "label": "Portfolio", "menuParent": "usersItems" }}
      />
      <ResourceGuesser
        name="experiences"
        icon={CategoryIcon}
        list={ArtistExperienceList}
        edit={ArtistExperienceEdit}
        create={ArtistExperienceCreate}
        options={{ "label": "Artist Exp.", "menuParent": "usersItems" }}
      />
      <ResourceGuesser
        name="portfolio_categories"
        icon={CategoryIcon}
        list={PortfolioCategoryList}
        edit={PortfolioCategoryEdit}
        create={PortfolioCategoryCreate}
        options={{ "label": "Portfolio Categories", "menuParent": "usersItems" }}
      />
      <ResourceGuesser
        name="portfolio_sub_categories"
        icon={CategoryIcon}
        list={PortfolioSubCategoryList}
        edit={PortfolioSubCategoryEdit}
        create={PortfolioSubCategoryCreate}
        options={{ "label": "Portfolio Sub Categories", "menuParent": "usersItems" }}
      />
      {/* end Users */}

      {/* Products */}
      <ResourceGuesser name="productsItems" icon={AllInboxIcon} options={{ "label": "Manage Products", "isMenuParent": true }} />
      <ResourceGuesser
        name="categories"
        icon={CategoryIcon}
        list={CategoryList}
        edit={CategoryEdit}
        create={CategoryCreate}
        options={{ "label": "Categories", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="sub_categories"
        icon={CategoryIcon}
        list={SubCategoryList}
        edit={SubCategoryEdit}
        create={SubCategoryCreate}
        options={{ "label": "Sub Categories", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="collections"
        icon={CategoryIcon}
        list={CollectionList}
        edit={CollectionEdit}
        create={CollectionCreate}
        options={{ "label": "Collections", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="products"
        icon={AllInboxIcon}
        list={ProductList}
        edit={ProductEdit}
        create={ProductCreate}
        options={{ "label": "Products", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="product_files"
        icon={AllInboxIcon}
        list={FilesList}
        edit={FilesEdit}
        create={FilesCreate}
        options={{ "label": "Products files", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="price_rules"
        icon={AttachMoneyIcon}
        list={PriceRulesList}
        edit={PriceRulesEdit}
        create={PriceRulesCreate}
        options={{ "label": "Price rules", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="price_intls"
        icon={AttachMoneyIcon}
        list={PriceIntlList}
        edit={PriceIntlEdit}
        create={PriceIntlCreate}
        options={{ "label": "Price intl", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="group_steps"
        icon={SettingsIcon}
        list={GroupStepList}
        edit={GroupStepEdit}
        create={GroupStepCreate}
        options={{ "label": "Group Step", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="group_step_positions"
        icon={SettingsIcon}
        list={GroupStepPositionList}
        edit={GroupStepPositionEdit}
        create={GroupStepPositionCreate}
        options={{ "label": "Group Step Position", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="steps"
        icon={SettingsIcon}
        list={StepList}
        edit={StepEdit}
        create={StepCreate}
        options={{ "label": "Steps", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="group_step_formats"
        icon={SettingsIcon}
        list={GroupStepFormatList}
        edit={GroupStepFormatEdit}
        create={GroupStepFormatCreate}
        options={{ "label": "Group Step Format", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="group_step_format_positions"
        icon={SettingsIcon}
        list={GroupStepFormatPositionList}
        edit={GroupStepFormatPositionEdit}
        create={GroupStepFormatPositionCreate}
        options={{ "label": "Group Step Format Position", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="step_formats"
        icon={SettingsIcon}
        list={StepFormatList}
        edit={StepFormatEdit}
        create={StepFormatCreate}
        options={{ "label": "Formats", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="step_format_attributes"
        icon={SettingsIcon}
        list={StepFormatAttributeList}
        edit={StepFormatAttributeEdit}
        create={StepFormatAttributeCreate}
        options={{ "label": "Format Attributes", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="step_format_values"
        icon={SettingsIcon}
        list={StepFormatValueList}
        edit={StepFormatValueEdit}
        create={StepFormatValueCreate}
        options={{ "label": "Format Values", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="step_types"
        icon={SettingsIcon}
        list={StepTypeList}
        edit={StepTypeEdit}
        create={StepTypeCreate}
        options={{ "label": "Types", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="step_type_values"
        icon={SettingsIcon}
        list={StepTypeValueList}
        edit={StepTypeValueEdit}
        create={StepTypeValueCreate}
        options={{ "label": "Type Values", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="step_type_value_rules"
        icon={SettingsIcon}
        list={StepTypeValueRulesList}
        edit={StepTypeValueRulesEdit}
        create={StepTypeValueRulesCreate}
        options={{ "label": "Type Values Rules", "menuParent": "productsItems" }}
      />
      <ResourceGuesser
        name="step_type_value_intls"
        icon={SettingsIcon}
        list={StepTypeValueIntlList}
        edit={StepTypeValueIntlEdit}
        create={StepTypeValueIntlCreate}
        options={{ "label": "Type Values Intl", "menuParent": "productsItems" }}
      />
      {/* end Products */}

      {/* Orders */}
      <ResourceGuesser name="ordersItems" icon={AddShoppingCartIcon} options={{ "label": "Manage Orders", "isMenuParent": true }} />
      <ResourceGuesser
        name="shipping_methods"
        icon={LocalShippingIcon}
        list={ShippingMethodList}
        edit={ShippingMethodEdit}
        create={ShippingMethodCreate}
        options={{ "label": "Shipping Method", "menuParent": "ordersItems" }}
      />
      <ResourceGuesser
        name="orders"
        icon={AddShoppingCartIcon}
        list={OrderList}
        edit={OrderEdit}
        show={OrderShow}
        create={OrderCreate}
        options={{ "label": "Orders", "menuParent": "ordersItems" }}
      />
      <ResourceGuesser
        name="projects"
        icon={AddShoppingCartIcon}
        list={ProjectList}
        show={ProjectShow}
        options={{ "label": "Projects", "menuParent": "ordersItems" }}
      />
      <ResourceGuesser
        name="payments"
        icon={AddShoppingCartIcon}
        list={PaymentList}
        options={{ "label": "Payments", "menuParent": "ordersItems" }}
      />
      <ResourceGuesser
        name="order_artists"
        icon={PersonIcon}
        list={OrderArtistList}
        options={{ "label": "Artists", "menuParent": "ordersItems" }}
      />
      <ResourceGuesser
        name="submits"
        icon={PersonIcon}
        list={OrderArtistSubmitList}
        options={{ "label": "Submits", "menuParent": "ordersItems" }}
      />
      <ResourceGuesser
        name="order_products"
        icon={AllInboxIcon}
        list={OrderProductList}
        options={{ "label": "Products", "menuParent": "ordersItems" }}
      />
      <ResourceGuesser
        name="order_product_steps"
        icon={SettingsIcon}
        list={OrderProductStepList}
        options={{ "label": "Product Steps", "menuParent": "ordersItems" }}
      />
      <ResourceGuesser
        name="order_artists_reclaim_payments"
        icon={SettingsIcon}
        list={OrderArtistReclaimPaymentList}
        options={{ "label": "Artist Reclaim Payment", "menuParent": "ordersItems" }}
      />
      {/* end Orders */}

      {/* Marketplace */}
      <ResourceGuesser name="marketplace" icon={SettingsIcon} options={{ "label": "General", "isMenuParent": true }} />
      
      <ResourceGuesser
        name="celebrities"
        icon={PersonIcon}
        list={CelebrityList}
        edit={CelebrityEdit}
        create={CelebrityCreate}
        options={{ "label": "Celebrity", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="group_faqs"
        icon={LiveHelpIcon}
        list={GroupFaqList}
        edit={GroupFaqEdit}
        create={GroupFaqCreate}
        options={{ "label": "Group Faq", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="group_faq_positions"
        icon={LiveHelpIcon}
        list={GroupFaqPositionList}
        edit={GroupFaqPositionEdit}
        create={GroupFaqPositionCreate}
        options={{ "label": "Group Faq Position", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="faqs"
        icon={LiveHelpIcon}
        list={FaqList}
        edit={FaqEdit}
        create={FaqCreate}
        options={{ "label": "Faq", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="faq_answers"
        icon={LiveHelpIcon}
        list={FaqAnswerList}
        edit={FaqAnswerEdit}
        create={FaqAnswerCreate}
        options={{ "label": "Faq Answers", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="blogs"
        icon={RssFeedIcon}
        list={BlogList}
        edit={BlogEdit}
        create={BlogCreate}
        options={{ "label": "Blog", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="blog_categories"
        icon={RssFeedIcon}
        list={BlogCategoryList}
        edit={BlogCategoryEdit}
        create={BlogCategoryCreate}
        options={{ "label": "Blog category", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="files"
        icon={ImageIcon}
        list={FileList}
        edit={FileEdit}
        create={FileCreate}
        options={{ "label": "Files management", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="currencies"
        icon={AttachMoneyIcon}
        list={CurrencyList}
        options={{ "label": "Currencies", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="languages"
        icon={LanguageIcon}
        list={LanguageList}
        options={{ "label": "Languages", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="taxes"
        icon={AttachMoneyIcon}
        list={TaxList}
        options={{ "label": "Taxes", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="offers"
        icon={LocalOfferIcon}
        list={OffersList}
        edit={OffersEdit}
        create={OffersCreate}
        options={{ "label": "Offers", "menuParent": "marketplace" }}
      />
      <ResourceGuesser
        name="tags"
        icon={TagIcon}
        list={TagList}
        edit={TagEdit}
        create={TagCreate}
        options={{ "label": "Tags", "menuParent": "marketplace" }}
      />
      {/* end Marketplace */}
    </HydraAdmin>
);
export default App;

