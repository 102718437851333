import React  from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
  } from '@material-ui/core';

  import { useCreate, useCreateSuggestionContext } from 'react-admin';

const CreateInAutocomplete = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [title, setTitle] = React.useState(filter || '');
    const [name, setName] = React.useState(filter || '');
    const [create] = useCreate();

    const handleSubmit = event => {
        event.preventDefault();
        create(
            'tags',
            {
              title: title,
              name: name,
            },
            {
                onSuccess: ({ data }) => {
                    setTitle('');
                    setName('');
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="Title"
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                        autoFocus
                    />
                    <TextField
                        label="Name"
                        value={name}
                        onChange={event => setName(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateInAutocomplete;