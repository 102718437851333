import React  from 'react';
import { TextField, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const FilesList = (props) => (
    <ListGuesser {...props}>
      <TextField source="position" />
      <ReferenceField label="File" source="file" reference="files">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceArrayField label="Products" source="products" reference="products">
        <SingleFieldList>
            <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
    </ListGuesser>
);

export default FilesList;