import React  from 'react';
import { Layout } from 'react-admin';
import appBar from './appBar';
import TreeMenu from '@bb-tech/ra-treemenu';

const CartoonelyLayout = props => <Layout
    {...props}
    appBar={appBar}
    menu={TreeMenu}
/>;

export default CartoonelyLayout;
