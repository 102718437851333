import React  from 'react';
import { TextField, ReferenceArrayField, DateField, Datagrid, ReferenceField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `price_rules/create`,
          state: { record: omitId(record) }
        }}/>);
};

const PriceRulesList = (props) => (
    <ListGuesser {...props}>
      <TextField source="title" />
      <ReferenceArrayField label="Price intl" reference="price_intls" source="priceIntl">
        <Datagrid>
          <TextField source="price" />
          <ReferenceField
            label="Currency"
            source="currency"
            reference="currencies"
        >
            <TextField source="code" />
        </ReferenceField>
        </Datagrid>
      </ReferenceArrayField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <CloneElement />
    </ListGuesser>
);

export default PriceRulesList;