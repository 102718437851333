import React  from 'react';
import { ReferenceField, TextField, ReferenceArrayField, SingleFieldList, ChipField, FunctionField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const entrypoint = "https://d1wqdzlxqamchi.cloudfront.net/"

const FilenameField = props => (
  <FunctionField 
    {...props}
    render={record => {
      if (!record.fileList) return;
      var ext =  record.fileList.path.split('.').pop(); 

      if (ext === "jpg" || ext === "png" ||  ext === "jpeg" || ext === "webp") {
        return (
          <img
            alt={record.alt}
            src={`https://cdnserver.cartoonely.com/upload/w_200,h_200,q_40/${encodeURI(`${entrypoint}media/${record.fileList.path}`)}`}
            width={100}
          />
        )
      }
    }}
  />
);

const PortfolioCategoryList = (props) => (
    <ListGuesser {...props}>
    <FilenameField />
      <TextField source="name" />
      <TextField source="title" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField
        label="SubCategories"
        reference="portfolio_sub_categories"
        source="subCategories"
      >
        <SingleFieldList>
            <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
    </ListGuesser>
  );

export default PortfolioCategoryList;