import React  from 'react';
import { CreateGuesser } from "@api-platform/admin";
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const PriceIntlCreate = (props) => (
  <CreateGuesser {...props}>
    <Typography variant="h4" gutterBottom component="div">
      Create new price intl
    </Typography>

    <TextInput source="price" />
    <TextInput source="priceSale" />
    <ReferenceInput
        source="currency"
        reference="currencies"
        label="Currency"
        filterToQuery={searchText => ({ code: searchText })}
      >
        <AutocompleteInput optionText="code" />
    </ReferenceInput>
  </CreateGuesser>
  );

export default PriceIntlCreate;