import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, AutocompleteArrayInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectArrayInput } from 'react-admin';
import { useGetOne } from 'react-admin';

const Experience = ({ record }) => {
  const { data, loading } = useGetOne('portfolio_sub_categories', record.subCategory);
  if (loading) { return "Loading..."; }
  if (data) {
    return `${data.name} / ${record.status}`;
  }

  return `${record.position}`;
};

const ArtistEdit = (props) => (
  <EditGuesser {...props}>
    <ReferenceInput
      source="user"
      reference="users"
      label="User"
      perPage={10000000}
      filter={{ 'roles': "ROLE_ARTIST" }}
    >
      <AutocompleteInput optionText={user => user ? `${user.lastName} ${user.firstName}` : "email"} />
    </ReferenceInput>

    <ReferenceArrayInput
      source="languages"
      reference="languages"
      label="Language"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      source="subCategories"
      reference="sub_categories"
      label="Subcategories"
      filterToQuery={searchText => ({ title: searchText })}
      perPage={1000}
    >
      <AutocompleteArrayInput optionText="title" />
    </ReferenceArrayInput>

<ReferenceArrayInput
  source="experiences"
  reference="experiences"
  label="Experiences"
  filterToQuery={searchText => ({ title: searchText })}
  perPage={1000}
>
  <SelectArrayInput optionText={experiences => <Experience record={experiences} />} />
</ReferenceArrayInput>

    <InputGuesser source="status" />
    <BooleanInput source="enabled" defaultValue={true} />
    <InputGuesser source="rating" />

    <ReferenceArrayInput
      source="portfolios"
      reference="portfolios"
      label="Portfolios"
      filterToQuery={searchText => ({ title: searchText })}
      perPage={1000}
    >
      <AutocompleteArrayInput optionText="title" />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      source="artistReclaimPayment"
      reference="order_artists_reclaim_payments"
      label="Reclaim payments"
      perPage={1000}
    >
      <AutocompleteArrayInput optionText="amount" />
    </ReferenceArrayInput>
    <InputGuesser source="urlPaypalMe" />
    <InputGuesser source="iban" />

  </EditGuesser>
  );

export default ArtistEdit;