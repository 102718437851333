import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";

const TagCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
  </CreateGuesser>
  );

export default TagCreate;