import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import Upload from "../Component/Upload";

const FileCreate = (props) => (
  <CreateGuesser {...props}>
    <ReferenceInput
      source="language"
      reference="languages"
      label="Language"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
		<Upload showFiles={true} />
    <InputGuesser source="title" />
    <InputGuesser source="alt" />
    <InputGuesser source="cloud" />
  </CreateGuesser>
  );

export default FileCreate;