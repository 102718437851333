import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { BooleanInput, ReferenceInput, SelectInput, AutocompleteInput } from 'react-admin';

const StepCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="categoryName" />
    <InputGuesser source="title" />
    <BooleanInput defaultValue={false} source="upsell" />
    <BooleanInput defaultValue={false} source="required" />
    <BooleanInput defaultValue={false} source="enabled" />
    <InputGuesser source="descriptionRequired" />
    <ReferenceInput
      label="Step Type"
      source="stepType"
      reference="step_types"
      perPage={1900}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Language" source="language" reference="languages">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
  );

export default StepCreate;