import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import InputAdornment from '@material-ui/core/InputAdornment';

const OffersCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="code" />
    <InputGuesser source="amount" />
    <InputGuesser
      source="percent"
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
      }}
    />
    <InputGuesser source="enabled" />
  </CreateGuesser>
  );

export default OffersCreate;