import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import {
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
} from 'react-admin';
import { RichTextInput } from '@agilie/ra-tinymce-input';
import Typography from '@material-ui/core/Typography';
import { useGetOne } from 'react-admin';

const Artist = ({ record }) => {
  const { data, loading } = useGetOne('users', record.user);
  if (loading) { return "Loading..."; }
  if (data) {
    return `${data.firstName} ${data.lastName}`;
  }

  return `${record.id}`;
};

const ProductEdit = (props) => (
  <EditGuesser {...props}>
    <Typography variant="h4" gutterBottom component="div">
      Edit product
    </Typography>

    <ReferenceInput
      source="language"
      reference="languages"
      label="Language"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <ReferenceArrayInput
      label="SubCategories"
      reference="sub_categories"
      source="subCategories"
      perPage={1900}
    >
      <AutocompleteArrayInput source="name" />
    </ReferenceArrayInput>
    <ReferenceInput
      label="Artist"
      source="artist"
      reference="artists"
      perPage={1900}
    >
      <SelectInput optionText={artist => <Artist record={artist} />} />
    </ReferenceInput>
    <ReferenceInput
      label="Price rules"
      source="priceRules"
      reference="price_rules"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <InputGuesser source="metaTitle" />
    <p>Description:</p>
    <RichTextInput label="Description" source="description"
      apiKey='kajmvp4egjobgynq3phsfqif168so5skv2apq56c5zmjum5a'
      init={{
          height: 500,
          menubar: true,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
    <p>Description SEO (Meta):</p>
    <RichTextInput label="Description SEO (Meta)" source="descriptionSeo"
      apiKey='kajmvp4egjobgynq3phsfqif168so5skv2apq56c5zmjum5a'
      init={{
          height: 500,
          menubar: true,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
    <p>Content:</p>
    <RichTextInput label="Content" source="content"
      apiKey='kajmvp4egjobgynq3phsfqif168so5skv2apq56c5zmjum5a'
      init={{
          height: 500,
          menubar: true,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
    
    <ReferenceInput label="Tax" source="tax" reference="taxes">
      <SelectInput optionText="percent" />
    </ReferenceInput>

    <InputGuesser source="sku" />
    <BooleanInput source="digitalProduct" defaultValue={false} />

    <BooleanInput source="bestseller" defaultValue={false} />
    <BooleanInput source="new" defaultValue={false} />
    <BooleanInput source="enabled" defaultValue={true} />
    <BooleanInput source="popular" defaultValue={false} />

    <ReferenceInput
      label="Group step"
      source="groupSteps"
      reference="group_steps"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>

    <ReferenceInput
      label="Group step format"
      source="groupStepFormat"
      reference="group_step_formats"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>

    <ReferenceInput
      label="Group Faq"
      source="groupFaq"
      reference="group_faqs"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>

    <ReferenceArrayInput
      label="Products Same Style"
      reference="products"
      source="productSameStyle"
      perPage={1900}
    >
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="Products Other Universes"
      reference="products"
      source="productOtherUniverses"
      perPage={1900}
    >
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="Reviews"
      source="reviews"
      reference="reviews"
      perPage={10000}
    >
      <AutocompleteArrayInput
        optionText="message"
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
      />
    </ReferenceArrayInput>

    <BooleanInput source="lastPosition" />
    <ArrayInput label="Add new Product Files" source="productFiles" reference="product_files">
      <SimpleFormIterator getItemLabel={(index) => `${index + 1}. Product files`}>
        <NumberInput
            label="Position"
            source="position"
        />
        <ReferenceInput
          label="File"
          source="file"
          reference="files"
          perPage={100000}
          filter={{ title: "product -" }}
        >
          <AutocompleteInput optionText="title" shouldRenderSuggestions={(value) => value.trim().length >= 3} />
        </ReferenceInput>
      </SimpleFormIterator>
    </ArrayInput>
    <ReferenceArrayInput
      label="Resources"
      source="resources"
      reference="files"
      perPage={500}
      filter={{ title: "Ressource (" }}
    >
      <AutocompleteArrayInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
      />
    </ReferenceArrayInput>
  </EditGuesser>
  );

export default ProductEdit;