import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import {
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

const ShippingMethodCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="code" />
    <InputGuesser source="deadline" />
    <InputGuesser source="drawing_delivery" />
    <ReferenceInput
      label="Price rules"
      source="priceRules"
      reference="price_rules"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <InputGuesser source="enabled" />
  </CreateGuesser>
  );

export default ShippingMethodCreate;