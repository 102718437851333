import React  from 'react';
import { ReferenceField, TextField, DateField, FunctionField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const entrypoint = "https://d1wqdzlxqamchi.cloudfront.net/"

const FilenameField = props => (
    <FunctionField 
      {...props}
      render={record => {
          return (
            <img
              alt={record.alt}
              src={`https://cdnserver.cartoonely.com/upload/w_200,h_200,q_40/${encodeURI(`${entrypoint}media/upload/Artist/Project/${record.filePathProject}`)}`}
              width={100}
            />
          )
      }}
    />
);

const OrderArtistSubmitList = (props) => (
    <ListGuesser {...props}>
      <FilenameField />
      <ReferenceField label="Order" source="artists" reference="order_artists">
        <ReferenceField label="Order" source="order" reference="orders">
          <TextField source="reference" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField label="Order Product" source="artists" reference="order_artists">
        <ReferenceField label="Order Product" source="orderProduct" reference="order_products">
          <ReferenceField label="Order Product" source="product" reference="products">
            <TextField source="title" />
          </ReferenceField>
        </ReferenceField>
      </ReferenceField>
      <ReferenceField label="Artist FirstName" source="artists" reference="order_artists">
        <ReferenceField label="Artist FirstName" source="artist" reference="artists">
          <ReferenceField label="Artist FirstName" source="user" reference="users">
            <TextField source="firstName" />
          </ReferenceField>
        </ReferenceField>
      </ReferenceField>
      <ReferenceField label="Artist LastName" source="artists" reference="order_artists">
        <ReferenceField label="Artist LastName" source="artist" reference="artists">
          <ReferenceField label="Artist LastName" source="user" reference="users">
            <TextField source="lastName" />
          </ReferenceField>
        </ReferenceField>
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </ListGuesser>
);

export default OrderArtistSubmitList;