/* eslint-disable array-callback-return */
import React  from 'react';
import { ReferenceField, TextField, BooleanField, DateField,
  Tab,
  TabbedShowLayout, FunctionField } from "react-admin";
import { ShowGuesser } from "@api-platform/admin";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const entrypoint = "https://d1wqdzlxqamchi.cloudfront.net/"

const ProjectShow = (props) => {
  return (
    <ShowGuesser {...props}>
      <TabbedShowLayout>
        <Tab label="Order">
          <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Payment : <Chip label={<BooleanField source="payment" />} color="primary" variant="outlined" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Uuid : <TextField source="uuid" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Reference : <TextField source="reference" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Date : <DateField source="createdAt" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Last update : <DateField source="updatedAt" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Status : <Chip label={<TextField source="status" />} color="primary" variant="outlined" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Quantity : <TextField source="quantity" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Total : <TextField source="totalPrice" />&nbsp;
                <ReferenceField label="Currency" source="currency" reference="currencies">
                  <TextField source="code" />
                </ReferenceField>
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Tree Nation : <BooleanField source="useTreeNation" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Licence Commercial : <BooleanField source="useLicenceCommercial" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Sources : <BooleanField source="useSources" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Coupon : <TextField source="coupon" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Source stripe : <TextField source="shippingDest" />
              </Typography>
            </Box>
        </Tab>
        <Tab label="Customer" path="customer">
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Last name :&nbsp;
              <ReferenceField label="Last name" source="user" reference="users">
                <TextField source="lastName" />
              </ReferenceField>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              First name :&nbsp;
              <ReferenceField label="First name" source="user" reference="users">
                <TextField source="firstName" />
              </ReferenceField>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Company name :&nbsp;
              <ReferenceField label="Company name" source="user" reference="users">
                <TextField source="companyName" />
              </ReferenceField>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Email :&nbsp; 
              <TextField source="email" /> /&nbsp; 
              <ReferenceField label="Email" source="user" reference="users">
                <TextField source="email" />
              </ReferenceField>
            </Typography>
          </Box>
        </Tab>
        <Tab label="Billing" path="billing">
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Company : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="company" />
              </ReferenceField>
              <br/>
              First name : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="firstName" />
              </ReferenceField>
              <br/>
              Last name : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="lastName" />
              </ReferenceField>
              <br/>
              Address 1 : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="address1" />
              </ReferenceField>
              <br/>
              Address 2 : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="address2" />
              </ReferenceField>
              <br/>
              Address 3 : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="address3" />
              </ReferenceField>
              <br/>
              City : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="city" />
              </ReferenceField>
              <br/>
              State : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="state" />
              </ReferenceField>
              <br/>
              Zip code : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="zipCode" />
              </ReferenceField>
              <br/>
              Country : <ReferenceField label="addressBilling" source="addressBilling" reference="addresses">
                <TextField source="country" />
              </ReferenceField>
            </Typography>
          </Box>
        </Tab>
        <Tab label="Steps" path="steps">
          <Box>
          <Typography sx={{ fontSize: 14 }} gutterBottom>
              Budget : <TextField source="budget" />&nbsp;
                <ReferenceField label="Currency" source="currency" reference="currencies">
                  <TextField source="code" />
                </ReferenceField>
              <br/>
              Title : <TextField source="title" />
              <br/>
              Brief : <TextField source="brief" />
              <br/>
              Deadline : <TextField source="deadline" />
              <br/>
              Artist : <ReferenceField label="Artist" source="artist" reference="artists">
                        <ReferenceField label="First name" source="user" reference="users">
                          <TextField source="firstName" />
                        </ReferenceField>
                      </ReferenceField>&nbsp;<ReferenceField label="Artist" source="artist" reference="artists">
                        <ReferenceField label="Last name" source="user" reference="users">
                          <TextField source="lastName" />
                        </ReferenceField>
                      </ReferenceField>
              <br/>
              Category : <ReferenceField label="Category" source="category" reference="categories">
                          <TextField source="name" />
                        </ReferenceField>
              <br/>
              Sub category : <ReferenceField label="Sub category" source="subCategory" reference="sub_categories">
                          <TextField source="name" />
                        </ReferenceField>
              <br/>
              Files : <FunctionField label="Files" render={record => {
                        return (
                          <>
                            {record.filesData.map((file) => (
                              <img src={`https://cdnserver.cartoonely.com/upload/w_200,h_200,q_40/${encodeURI(`${entrypoint}media/upload/Customer/${file.fileList.path}`)}`} title={file.title} alt={file.title} />
                            ))}
                          </>
                        )
                      }} />
              <br/>
              Resources : <FunctionField label="Resources" render={record => {
                console.log(record.resourcesData);
                        return (
                          <>
                            {record.resourcesData.map((portfolio) => (
                              <img src={`https://cdnserver.cartoonely.com/upload/w_200,h_200,q_40/${encodeURI(`${entrypoint}media/${portfolio.fileList.path}`)}`} title={portfolio.title} alt={portfolio.title} />
                            ))}
                          </>
                        )
                      }} />
              <br/>
          </Typography>
          </Box>
        </Tab>
      </TabbedShowLayout>
    </ShowGuesser>
)};

export default ProjectShow;