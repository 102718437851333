import React  from 'react';
import { ReferenceArrayField, SingleFieldList, ChipField, ReferenceField, TextField, BooleanField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const BlogList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="title" />
      <TextField source="slug" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="File" source="file" reference="files">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Category" source="category" reference="blog_categories">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceArrayField
        label="Tags"
        reference="tags"
        source="tags"
      >
        <SingleFieldList>
            <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField source="enabled" />
    </ListGuesser>
  );

export default BlogList;