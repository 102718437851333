import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import {
  ReferenceArrayInput,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  BooleanInput,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

const ReviewCreate = (props) => (
  <CreateGuesser {...props}>
    <Typography variant="h4" gutterBottom component="div">
      Create new review
    </Typography>

    <ReferenceInput
      source="user"
      reference="users"
      label="User"
      perPage={10000000}
    >
      <AutocompleteInput optionText={user => user ? `${user.lastName} ${user.firstName}` : "email"} />
    </ReferenceInput>

    <InputGuesser source="message" />
    <InputGuesser source="rating" />

    <ReferenceArrayInput label="Product" source="products" reference="products" perPage={100000000}>
      <AutocompleteArrayInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
      />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="File"
      source="files"
      reference="files"
      perPage={10000}
      filter={{ title: "Avis" }}
    >
      <AutocompleteArrayInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 1}
      />
    </ReferenceArrayInput>

    <InputGuesser source="createdAt" />
    <BooleanInput source="enabled" defaultValue={true} />

  </CreateGuesser>
  );

export default ReviewCreate;