import React  from 'react';
import { ReferenceField, TextField, DateField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const OrderArtistReclaimPaymentList = (props) => (
    <ListGuesser {...props}>
      <TextField source="orderIds" />
      <TextField source="amount" />
      <DateField source="paidAt" />
      <ReferenceField label="Artist" source="artists" reference="artists">
        <ReferenceField label="Artist" source="user" reference="users">
          <TextField source="firstName" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField label="Artist" source="artists" reference="artists">
        <ReferenceField label="Artist" source="user" reference="users">
          <TextField source="lastName" />
        </ReferenceField>
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </ListGuesser>
);

export default OrderArtistReclaimPaymentList;