import React  from 'react';
import { TextField, ReferenceField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const ArtistExperienceList = (props) => (
    <ListGuesser {...props}>
      <ReferenceField
        label="Sub categories"
        reference="portfolio_sub_categories"
        source="subCategory"
      >
        <TextField source="name" />
      </ReferenceField>

      <TextField source="status" />
    </ListGuesser>
  );

export default ArtistExperienceList;