import React  from 'react';
import { ReferenceField, TextField, ReferenceArrayField, Datagrid, SingleFieldList, ChipField, BooleanField, useRecordContext, NumberField, DateField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";
import { Rating } from 'react-simple-star-rating'

const RatingValue = ({ source }) => {
  const record = useRecordContext();
  return (<Rating size={20} ratingValue={record.rating * 20} readonly />);
};

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `products/create`,
          state: { record: omitId(record) }
        }}/>);
};

const ProductList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="title" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label="Price Rules" reference="price_rules" source="priceRules">
          <ReferenceArrayField
            label="Intl"
            source="priceIntl"
            reference="price_intls"
          >
            <Datagrid>
              <TextField source="price" />
              <ReferenceField
                  label="Currency"
                  source="currency"
                  reference="currencies"
              >
                <TextField source="code" />
              </ReferenceField>
            </Datagrid>
          </ReferenceArrayField>
      </ReferenceField>

      <ReferenceField label="Tax" source="tax" reference="taxes">
        <NumberField source="percent" />
      </ReferenceField>
      <><RatingValue source="rating" /></>
      <ReferenceArrayField
        label="Sub categories"
        reference="sub_categories"
        source="subCategories"
      >
        <SingleFieldList>
            <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField label="Artist" source="artist" reference="artists">
        <ReferenceField label="Artist" source="user" reference="users">
          <TextField source="firstName" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField label="Artist" source="artist" reference="artists">
        <ReferenceField label="Artist" source="user" reference="users">
          <TextField source="lastName" />
        </ReferenceField>
      </ReferenceField>
      <BooleanField source="bestseller" />
      <BooleanField source="new" />
      <BooleanField source="enabled" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <BooleanField source="lastPosition" />
      <CloneElement />
    </ListGuesser>
);

export default ProductList;