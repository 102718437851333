import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { TextInput } from 'react-admin';
import { RichTextInput } from '@agilie/ra-tinymce-input';

const FaqAnswerCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <TextInput source="issue" />
    <p>Answer:</p>
    <RichTextInput label="Answer" source="answer"
      apiKey='kajmvp4egjobgynq3phsfqif168so5skv2apq56c5zmjum5a'
      init={{
          height: 500,
          menubar: true,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />

  </CreateGuesser>
  );

export default FaqAnswerCreate;