import React  from 'react';
import { TextField, ReferenceArrayField, Datagrid, ReferenceField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `group_steps/create`,
          state: { record: omitId(record) }
        }}/>);
};

const GroupStepList = (props) => (
    <ListGuesser {...props}>
      <TextField source="title" />
      <ReferenceArrayField label="Group step position" reference="group_step_positions" source="groupStepPositions">
        <Datagrid>
          <TextField source="position" />
          <ReferenceField
            label="Step"
            source="step"
            reference="steps"
        >
            <TextField source="title" />
        </ReferenceField>
        </Datagrid>
      </ReferenceArrayField>
      <CloneElement />
    </ListGuesser>
);

export default GroupStepList;