import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

const GroupStepCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="title" />
    <ReferenceArrayInput
      label="Group step position"
      reference="group_step_positions"
      source="groupStepPositions"
      perPage={2000}
      filterToQuery={searchText => ({
        "step.title": searchText,
    })}
    >
      <AutocompleteArrayInput
        suggestionLimit={2000}
        optionText={groupStepPositions => `${groupStepPositions.position} / ${groupStepPositions.stepList.title}`}
      />
    </ReferenceArrayInput>
  </CreateGuesser>
  );

export default GroupStepCreate;