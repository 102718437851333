import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, AutocompleteInput, ReferenceInput, SelectInput, AutocompleteArrayInput } from 'react-admin';
import { RichTextInput } from '@agilie/ra-tinymce-input';
import CreateInAutocomplete from '../Tag/CreateInAutocomplete';
import { useGetOne } from 'react-admin';

const UserName = ({ record }) => {
  const { data, loading } = useGetOne('users', record.user);
  if (loading) { return "Loading..."; }
  if (data) {
    return `${data.lastName} ${data.firstName}`;
  }

  return `${record.id}`;
};

const PortfolioEdit = (props) => (
  <EditGuesser {...props}>
    <ReferenceInput
      label="Artist"
      source="artist"
      reference="artists"
      perPage={1900}
    >
      <SelectInput
        optionText={artist => <UserName record={artist} />}
      />
    </ReferenceInput>
    <InputGuesser source="title" />
    <InputGuesser source="numberView" />
    <p>Description:</p>
    <RichTextInput label="Description" source="description"
      apiKey='kajmvp4egjobgynq3phsfqif168so5skv2apq56c5zmjum5a'
      init={{
          height: 500,
          menubar: true,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
    <ReferenceInput
      label="File"
      source="file"
      reference="files"
      perPage={10000}
      filter={{ title: "Portfolio" }}
    >
      <AutocompleteInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
      />
    </ReferenceInput>
    <ReferenceArrayInput
      source="tags"
      reference="tags"
      perPage={10000}
    >
        <AutocompleteArrayInput
          create={<CreateInAutocomplete />}
          shouldRenderSuggestions={(value) => value.trim().length >= 2}
        />
    </ReferenceArrayInput>
    <ReferenceInput
      label="Category"
      source="category"
      reference="portfolio_categories"
      perPage={10000}
    >
      <AutocompleteInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 1}
      />
    </ReferenceInput>
    <ReferenceInput
      label="SubCategory"
      source="subCategory"
      reference="portfolio_sub_categories"
      perPage={10000}
    >
      <AutocompleteInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 1}
      />
    </ReferenceInput>
  </EditGuesser>
  );

export default PortfolioEdit;