import React  from 'react';
import { useGetList, Loading, Error  } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const OrdersByStatus = ({ label, status, widthList }) => {
    const { data, ids, total, loading, error } = useGetList(
        'orders',
        { page: 1, perPage: 10 },
        { field: 'created_at', order: 'DESC' },
        { status: status }
    );
    if (loading) { return <Loading />; }
    if (error) { return <Error />; }
    if (!data) return null;

    return (
        <Card sx={{ maxWidth: 275 }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                    {label} orders
                </Typography>
                <Typography variant="h5" component="div">
                    {total} orders
                </Typography>
                {widthList && (
                    <Typography variant="body2">
                        <ul>
                            {ids.map(id =>
                                <li key={id}>{data[id].reference}</li>
                            )}
                        </ul>
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default OrdersByStatus;