import React  from 'react';
import { FunctionField, ReferenceField, TextField, ReferenceArrayField, SingleFieldList, ChipField, DateField, BooleanField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const UserList = (props) => (
    <ListGuesser {...props}>
      <FunctionField label="Name" render={record => `${record.firstName} ${record.lastName}`} />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="roles" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="code" />
      </ReferenceField>
      <ReferenceArrayField
        label="Addresses"
        reference="addresses"
        source="address"
      >
        <SingleFieldList>
            <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <BooleanField source="enabled" />
      <BooleanField source="isBtob" />
    </ListGuesser>
  );

export default UserList;