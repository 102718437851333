import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { RichTextInput } from '@agilie/ra-tinymce-input';

const BlogCategoryCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <InputGuesser source="metaTitle" />
    <p>Hero text:</p>
    <RichTextInput label="Hero text" source="heroText"
      apiKey='kajmvp4egjobgynq3phsfqif168so5skv2apq56c5zmjum5a'
      init={{
          height: 500,
          menubar: true,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
    <p>Meta Description:</p>
    <RichTextInput label="Meta Description" source="metaDescription"
      apiKey='kajmvp4egjobgynq3phsfqif168so5skv2apq56c5zmjum5a'
      init={{
          height: 500,
          menubar: true,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
    <ReferenceInput
      label="File"
      source="file"
      reference="files"
      perPage={10000}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <ReferenceInput label="Language" source="language" reference="languages">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
  );

export default BlogCategoryCreate;