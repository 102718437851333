import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, AutocompleteArrayInput, ReferenceInput, SelectInput } from 'react-admin';

const FaqEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <ReferenceInput label="Language" source="language" reference="languages">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceArrayInput
      label="FAQ Answer"
      reference="faq_answers"
      source="faqAnswers"
      page={1}
      perPage={200}
    >
      <AutocompleteArrayInput
        suggestionLimit={200}
        optionText={faqAnswers => `${faqAnswers.title} / ${faqAnswers.issue.substring(0,30) + '...'}`}
      />
    </ReferenceArrayInput>
  </EditGuesser>
  );

export default FaqEdit;