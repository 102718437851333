import React  from 'react';
import { ReferenceField, TextField, NumberField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `taxes/create`,
          state: { record: omitId(record) }
        }}/>);
};

const TaxList = (props) => (
    <ListGuesser {...props}>
      <NumberField source="percent" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <CloneElement />
    </ListGuesser>
);

export default TaxList;