import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { useGetOne } from 'react-admin';

const CurrencyValue = ({ record }) => {
  const { data, loading } = useGetOne('currencies', record.currency);
  if (loading) { return "Loading..."; }
  if (data) {
    return `${record.price} / ${record.priceSale} / ${data.code}`;
  }

  return `${record.price} / ${record.priceSale}`;
};

const PriceRulesCreate = (props) => (
  <CreateGuesser {...props}>
    <Typography variant="h4" gutterBottom component="div">
      Create new price rules
    </Typography>

    <InputGuesser source="title" />
    <ReferenceArrayInput
      source="priceIntl"
      reference="price_intls"
      page={1}
      perPage={1900}
    >
      <SelectArrayInput optionText={priceIntl => <CurrencyValue record={priceIntl} />} />
    </ReferenceArrayInput>
  </CreateGuesser>
  );

export default PriceRulesCreate;