import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const FilesEdit = (props) => (
  <EditGuesser {...props}>
    <Typography variant="h4" gutterBottom component="div">
      Eidt files
    </Typography>

    <InputGuesser source="position" />
    <ReferenceInput
        source="file"
        reference="files"
        label="File"
      >
        <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <ReferenceArrayInput
        source="products"
        reference="products"
        label="Products"
      >
        <AutocompleteArrayInput optionText="title" />
    </ReferenceArrayInput>
  </EditGuesser>
  );

export default FilesEdit;