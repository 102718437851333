import React  from 'react';
import { TextField, NumberField, ReferenceField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `price_intls/create`,
          state: { record: omitId(record) }
        }}/>);
};

const PriceIntlList = (props) => (
    <ListGuesser {...props}>
      <NumberField source="price" />
      <NumberField source="priceSale" />
      <ReferenceField label="Currency" source="currency" reference="currencies">
        <TextField source="code" />
      </ReferenceField>
      <CloneElement />
    </ListGuesser>
);

export default PriceIntlList;