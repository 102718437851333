import React  from 'react';
import { TextField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `order_product_steps/create`,
          state: { record: omitId(record) }
        }}/>);
};

const OrderProductStepList = (props) => (
    <ListGuesser {...props}>
      <TextField source="value" />
      <CloneElement />
    </ListGuesser>
);

export default OrderProductStepList;