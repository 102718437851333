import React  from 'react';
import { ReferenceArrayField, Datagrid, ReferenceField, TextField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `step_type_value_rules/create`,
          state: { record: omitId(record) }
        }}/>);
};

const StepTypeValueRulesList = (props) => (
    <ListGuesser {...props}>
      <TextField source="title" />
      <ReferenceArrayField label="Name intl" reference="step_type_value_intls" source="typeValueIntl">
        <Datagrid>
          <TextField source="name" />
          <ReferenceField
            label="language"
            source="language"
            reference="languages"
        >
            <TextField source="name" />
        </ReferenceField>
        </Datagrid>
      </ReferenceArrayField>

      <CloneElement />
    </ListGuesser>
);

export default StepTypeValueRulesList;