import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import {
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

const ShippingMethodEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="code" />
    <InputGuesser source="deadline" />
    <InputGuesser source="drawing_delivery" />
    <ReferenceInput
      label="Price rules"
      source="priceRules"
      reference="price_rules"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <InputGuesser source="enabled" />
  </EditGuesser>
  );

export default ShippingMethodEdit;