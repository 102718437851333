/* eslint-disable array-callback-return */
import React  from 'react';
import { ReferenceField, TextField, BooleanField, DateField, ReferenceArrayField, Datagrid,
  Tab,
  TabbedShowLayout, FunctionField } from "react-admin";
import { ShowGuesser } from "@api-platform/admin";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const OrderShow = (props) => {
  return (
    <ShowGuesser {...props}>
      <TabbedShowLayout>
        <Tab label="Order">
          <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Payment : <Chip label={<BooleanField source="payment" />} color="primary" variant="outlined" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Uuid : <TextField source="uuid" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Reference : <TextField source="reference" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Date : <DateField source="createdAt" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Last update : <DateField source="updatedAt" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Status : <Chip label={<TextField source="status" />} color="primary" variant="outlined" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Quantity : <TextField source="quantity" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Total : <TextField source="totalPrice" />&nbsp;
                <ReferenceField label="Currency" source="currency" reference="currencies">
                  <TextField source="code" />
                </ReferenceField>
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Tax : <ReferenceField label="Tax" source="tax" reference="taxes">
                  <TextField source="percent" />
                </ReferenceField> / <TextField source="priceSalesTaxes" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Tree Nation : <BooleanField source="useTreeNation" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Tips : <TextField source="amountTips" />&nbsp;
                <ReferenceField label="Currency" source="currency" reference="currencies">
                  <TextField source="code" />
                </ReferenceField>
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Guarantee : <BooleanField source="useRefundedGuarantee" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Coupon : <TextField source="coupon" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Source stripe : <TextField source="shippingDest" />
              </Typography>
            </Box>
        </Tab>
        <Tab label="Customer" path="customer">
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Last name :&nbsp;
              <ReferenceField label="Last name" source="user" reference="users">
                <TextField source="lastName" />
              </ReferenceField>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              First name :&nbsp;
              <ReferenceField label="First name" source="user" reference="users">
                <TextField source="firstName" />
              </ReferenceField>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Email :&nbsp; 
              <TextField source="email" /> /&nbsp; 
              <ReferenceField label="Email" source="user" reference="users">
                <TextField source="email" />
              </ReferenceField>
            </Typography>
          </Box>
        </Tab>
        <Tab label="Delivery" path="delivery">
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Option : <TextField source="shippingOption" />
              <br/>
              Company : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="company" />
              </ReferenceField>
              <br/>
              First name : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="firstName" />
              </ReferenceField>
              <br/>
              Last name : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="lastName" />
              </ReferenceField>
              <br/>
              Address 1 : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="address1" />
              </ReferenceField>
              <br/>
              Address 2 : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="address2" />
              </ReferenceField>
              <br/>
              Address 3 : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="address3" />
              </ReferenceField>
              <br/>
              City : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="city" />
              </ReferenceField>
              <br/>
              State : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="state" />
              </ReferenceField>
              <br/>
              Zip code : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="zipCode" />
              </ReferenceField>
              <br/>
              Country : <ReferenceField label="addressDelivery" source="addressDelivery" reference="addresses">
                <TextField source="country" />
              </ReferenceField>
            </Typography>
          </Box>
        </Tab>
        <Tab label="Gelato" path="gelato">
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Method : <TextField source="shipmentMethodUid" />
              <br/>
              Delivery minimum day : <TextField source="shipmentMethodMinDeliveryDays" />
              <br/>
              Delivery maximum day : <TextField source="shipmentMethodMaxDeliveryDays" />
              <br/>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Dimensions : 
              <br/>
              Pixel :
              <br/>
              Width : <FunctionField label="Format" render={record => `${record.format[0][0].dimensions['px']['width']} px`} />
              &nbsp;/ Height : <FunctionField label="Format" render={record => `${record.format[0][0].dimensions['px']['height']} px`} />
              <br/>
              Centimeter :
              <br/>
              Width : <FunctionField label="Format" render={record => `${record.format[0][0].dimensions['cm']['width']} cm`} />
              &nbsp;/ Height : <FunctionField label="Format" render={record => `${record.format[0][0].dimensions['cm']['height']} cm`} />
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Format : <Chip label={<FunctionField label="Format" render={record => `${record.formatAttribute[0][0].title}`} />} color="primary" variant="outlined" />
            </Typography>
          </Box>
        </Tab>
        <Tab label="Products" path="products">
          <Box>
            <ReferenceArrayField label="Order Product" source="orderProduct" reference="order_products">
              <Datagrid>
                <ReferenceField label="Products" source="product" reference="products">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Prices" source="product" reference="products">
                  <ReferenceField label="Price Rules" reference="price_rules" source="priceRules">
                      <ReferenceArrayField
                        label="Intl"
                        source="priceIntl"
                        reference="price_intls"
                      >
                        <Datagrid>
                          <TextField source="price" />
                          <ReferenceField
                              label="Currency"
                              source="currency"
                              reference="currencies"
                          >
                            <TextField source="code" />
                          </ReferenceField>
                        </Datagrid>
                      </ReferenceArrayField>
                    </ReferenceField>
                </ReferenceField>
              </Datagrid>
            </ReferenceArrayField>
          </Box>
        </Tab>
        <Tab label="Artists" path="artists">
          <Box>
            <ReferenceArrayField label="Order Product" source="orderArtist" reference="order_artists">
              <Datagrid>
                <ReferenceField label="Product" source="orderProduct" reference="order_products">
                  <ReferenceField label="Product" source="product" reference="products">
                      <TextField source="name" />
                  </ReferenceField>
                </ReferenceField>
                <ReferenceField label="FirstName" source="artist" reference="artists">
                  <ReferenceField label="FirstName" source="user" reference="users">
                    <TextField source="firstName" />
                  </ReferenceField>
                </ReferenceField>
                <ReferenceField label="LastName" source="artist" reference="artists">
                  <ReferenceField label="LastName" source="user" reference="users">
                    <TextField source="lastName" />
                  </ReferenceField>
                </ReferenceField>
                <TextField source="status" />
                <TextField source="statusCustomer" />
              </Datagrid>
            </ReferenceArrayField>
          </Box>
        </Tab>
        <Tab label="Steps" path="steps">
          <Box>
            <FunctionField label="Format" render={record => {
              return (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Step Title</TableCell>
                        <TableCell align="right">Step Value</TableCell>
                        <TableCell align="right">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {record.steps[0].map((step) => {
                      const numberOfDataValid = step.data.reduce((acc, value) => acc + (value.value !== "0" && value.value !== "" ? 1 : 0), 0)
                      console.log(numberOfDataValid)

                      if (numberOfDataValid > 0) {
                        return (
                          <TableRow
                            key={step.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {step.title}
                            </TableCell>
                            <TableCell align="right">
                              {step.data.map((value) => {
                                if (value.value !== "0" && value.value !== "") {
                                return (
                                  <>
                                    {value.name}<br/>
                                  </>
                              )}})}
                            </TableCell>
                            <TableCell align="right">
                            {step.data.map((value) => {
                                if (value.value !== "0" && value.value !== "") {
                                return (
                                  <>
                                    {value.value}<br/>
                                  </>
                              )}})}
                            </TableCell>
                          </TableRow>
                        )
                      }
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>    
              )
            }} />
          </Box>
        </Tab>
      </TabbedShowLayout>
    </ShowGuesser>
)};

export default OrderShow;