import React  from 'react';
import { TextField, ReferenceArrayField, Datagrid, ReferenceField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `group_faqs/create`,
          state: { record: omitId(record) }
        }}/>);
};

const GroupFaqList = (props) => (
    <ListGuesser {...props}>
      <TextField source="title" />
      <ReferenceArrayField label="Group faq position" reference="group_faq_positions" source="groupFaqPosition">
        <Datagrid>
          <TextField source="position" />
          <ReferenceField
            label="Faq"
            source="faq"
            reference="faqs"
        >
            <TextField source="title" />
        </ReferenceField>
        </Datagrid>
      </ReferenceArrayField>
      <CloneElement />
    </ListGuesser>
);

export default GroupFaqList;