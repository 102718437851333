import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { RichTextInput } from '@agilie/ra-tinymce-input';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const PortfolioSubCategoryEdit = (props) => (
  <EditGuesser {...props}>
    <ReferenceInput
      label="File"
      source="file"
      reference="files"
      perPage={1500}
      filter={{ title: "Ressource (Subcategory) -" }}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <p>Description:</p>
    <RichTextInput label="Description" source="description"
      apiKey='kajmvp4egjobgynq3phsfqif168so5skv2apq56c5zmjum5a'
      init={{
          height: 500,
          menubar: true,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
  </EditGuesser>
  );

export default PortfolioSubCategoryEdit;