import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, SelectInput } from 'react-admin';

const StepTypeValueIntlCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <InputGuesser source="placeholder" />
    <ReferenceInput label="Language" source="language" reference="languages">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
  );

export default StepTypeValueIntlCreate;