import React  from 'react';
import { ReferenceField, TextField, BooleanField, CloneButton, DateField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `orders/create`,
          state: { record: omitId(record) }
        }}/>);
};

const OrderList = (props) => (
    <ListGuesser {...props}>
      <TextField source="uuid" />
      <TextField source="reference" />
      <ReferenceField label="Last name" source="user" reference="users">
        <TextField source="lastName" />
      </ReferenceField>
      <ReferenceField label="First name" source="user" reference="users">
        <TextField source="firstName" />
      </ReferenceField>
      <TextField source="quantity" />
      <TextField source="totalPrice" />
      <ReferenceField label="Currency" source="currency" reference="currencies">
        <TextField source="code" />
      </ReferenceField>
      <ReferenceField label="Tax" source="tax" reference="taxes">
        <TextField source="percent" />
      </ReferenceField>
      <TextField source="priceSalesTaxes" />
      <TextField source="status" />
      <TextField source="step" />
      <TextField source="email" />
      <TextField source="shippingOption" />
      <BooleanField source="payment" />
      <BooleanField source="useTreeNation" />
      <TextField source="amountTips" />
      <BooleanField source="useRefundedGuarantee" />
      <TextField source="shippingDest" />
      <TextField source="coupon" />
      <TextField source="shipmentMethodUid" />
      <TextField source="shipmentMethodMinDeliveryDays" />
      <TextField source="shipmentMethodMaxDeliveryDays" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <CloneElement />
    </ListGuesser>
);

export default OrderList;