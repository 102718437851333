import React from "react";
import { Redirect, Route } from "react-router-dom";
import { hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra, useIntrospection } from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const getHeaders = () =>
    localStorage.getItem("token")
        ? {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
        : {};
const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });
const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};
const apiDocumentationParser = async (entrypoint) => {
    try {
        const { api } = await parseHydraDocumentation(entrypoint, {
            headers: getHeaders,
        });
        if(null === localStorage.getItem("token"))
        {
            return {
                api: api,
                customRoutes: [<Route path="/" component={RedirectToLogin} />],
            };
        }
        return { api };
    } catch (result) {
        // Only useful when the API endpoint is secured
        if (result.status === 401) {
            localStorage.removeItem("token");
            return {
                api: result.api,
                customRoutes: [<Route path="/" component={RedirectToLogin} />],
            };
        }
        throw result;
    }
};
const dataProvider = baseHydraDataProvider(
    entrypoint,
    fetchHydra,
    apiDocumentationParser
);
export default dataProvider;