import React  from 'react';
import { ReferenceField, TextField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const FaqList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="title" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
    </ListGuesser>
  );

export default FaqList;