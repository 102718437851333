import React  from 'react';
import { FunctionField, DateField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const NormalizeOrderArtist = ({ record }) => {
  return record.orderArtist[0] ? `${record.orderArtist[0].artist.firstName}` : 'null';
};

const NormalizeCustomer = ({ record }) => {
  return record.customer ? `${record.customer.firstName} ${record.customer.lastName}` : 'null';
};

const NormalizeThreads = ({ record }) => {
  console.log(record.threads.messages.messages);
  if (record && record.threads && record.threads.messages && record.threads.messages.messages) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        {record.threads.messages.messages.map(( { body, contentType, authorId }, index ) => (
          <div key={index} style={{
            whiteSpace: 'pre-wrap',
            flex: '1'
          }}>
            {`Author Id : ${authorId}\nContent type : ${contentType}\nBody: ${body}\n\n`}
          </div>
        ))}
      </div>
    )
  }

  return 'null';
};

const MessengerList = (props) => (
    <ListGuesser {...props}>
      <FunctionField source="artist" render={orderArtist => <NormalizeOrderArtist record={orderArtist} />} />
      <FunctionField source="customer" render={customer => <NormalizeCustomer record={customer} />} />
      <FunctionField source="threads" render={threads => <NormalizeThreads record={threads} />} />

      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </ListGuesser>
);

export default MessengerList;