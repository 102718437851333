import React  from 'react';
import { ReferenceField, Datagrid, ReferenceArrayField, SingleFieldList, ChipField, TextField, CloneButton, BooleanField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `step_type_values/create`,
          state: { record: omitId(record) }
        }}/>);
};

const StepTypeValueList = (props) => (
    <ListGuesser {...props}>
      <TextField source="title" />

      <ReferenceField label="Name Rules" reference="step_type_value_rules" source="typeValueRules">
          <ReferenceArrayField
            label="Intl"
            source="typeValueIntl"
            reference="step_type_value_intls"
          >
            <Datagrid>
              <TextField source="name" />
              <ReferenceField
                  label="Language"
                  source="language"
                  reference="languages"
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceArrayField>
      </ReferenceField>

      <ReferenceField label="Price Rules" reference="price_rules" source="priceRules">
          <ReferenceArrayField
            label="Intl"
            source="priceIntl"
            reference="price_intls"
          >
            <Datagrid>
              <TextField source="price" />
              <ReferenceField
                  label="Currency"
                  source="currency"
                  reference="currencies"
              >
                <TextField source="code" />
              </ReferenceField>
            </Datagrid>
          </ReferenceArrayField>
      </ReferenceField>

      <TextField source="priceArtist" />
      <TextField source="limitText" />
      <TextField source="bestSeller" />
      <ReferenceArrayField
        label="Tags"
        reference="tags"
        source="tags"
      >
        <SingleFieldList>
            <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="Images"
        reference="files"
        source="images"
      >
        <SingleFieldList>
            <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="Resources"
        reference="files"
        source="resources"
      >
        <SingleFieldList>
            <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField source="byDefault" />
      <CloneElement />
    </ListGuesser>
);

export default StepTypeValueList;