import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, ReferenceArrayInput, SelectArrayInput, AutocompleteArrayInput, AutocompleteInput } from 'react-admin';

const StepTypeValueEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="title" />
    <ReferenceInput
      label="Name rules"
      source="typeValueRules"
      reference="step_type_value_rules"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <InputGuesser source="priceArtist" />
    <InputGuesser source="byDefault" />
    <InputGuesser source="limitText" />
    <InputGuesser source="bestSeller" />
    <ReferenceInput
      label="Price rules"
      source="priceRules"
      reference="price_rules"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <ReferenceArrayInput
      label="Images"
      source="images"
      reference="files"
      perPage={1000}
      enableGetChoices={({ q }) => (q ? q.length >= 5 : false)}
      sort={{field: 'id', order: 'DESC'}}
    >
      <AutocompleteArrayInput
        optionText="title"
        shouldRenderSuggestions={value => value.length >= 5}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Resources Artist"
      source="resources"
      reference="files"
      perPage={1000}
      filter={{ title: "Ressource -" }}
      enableGetChoices={({ q }) => (q ? q.length >= 5 : false)}
      sort={{field: 'id', order: 'DESC'}}
    >
      <AutocompleteArrayInput
        suggestionLimit={20}
        optionText="title" 
        shouldRenderSuggestions={(value) => value.trim().length >= 5}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Step Formats"
      source="stepFormats"
      reference="step_formats"
      perPage={1900}
    >
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>
    <ReferenceArrayInput label="Tags" source="tags" reference="tags">
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>
  </EditGuesser>
  );

export default StepTypeValueEdit;