import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { AutocompleteInput, ReferenceInput } from 'react-admin';

const ArtistExperienceCreate = (props) => (
  <CreateGuesser {...props}>
    <ReferenceInput
      label="SubCategory"
      source="subCategory"
      reference="portfolio_sub_categories"
      perPage={10000}
    >
      <AutocompleteInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 1}
      />
    </ReferenceInput>

    <InputGuesser source="status" />
  </CreateGuesser>
  );

export default ArtistExperienceCreate;