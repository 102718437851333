import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { useGetOne } from 'react-admin';

const GroupStepFormatPositionsName = ({ record }) => {
  const { data, loading } = useGetOne('step_formats', record.stepFormats);
  if (loading) { return "Loading..."; }
  if (data) {
    return `${record.position} / ${data.name}`;
  }

  return `${record.position}`;
};

const GroupStepFormatEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="title" />
    <ReferenceArrayInput
      label="Group step format position"
      reference="group_step_format_positions"
      source="groupStepFormatPosition"
      perPage={1900}
    >
      <SelectArrayInput
        optionText={groupStepFormatPositions => <GroupStepFormatPositionsName record={groupStepFormatPositions} />}
      />
    </ReferenceArrayInput>
  </EditGuesser>
  );

export default GroupStepFormatEdit;