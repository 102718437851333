import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

const StepFormatEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <InputGuesser source="description" />
    <ReferenceArrayInput
      label="Images"
      source="images"
      reference="files"
      perPage={10000}
    >
      <AutocompleteArrayInput optionText="title" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Step Format Attributes"
      source="stepFormatAttributes"
      reference="step_format_attributes"
      perPage={1900}
    >
      <AutocompleteArrayInput
        optionText="title"
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
      />
    </ReferenceArrayInput>
  </EditGuesser>
  );

export default StepFormatEdit;