import React  from 'react';
import { FileField, FileInput, ReferenceInput, SelectInput } from "react-admin";

const Upload = (props) => (
    <div classname="Upload">
        {false === true && props.showFiles && (
            <>
                <ReferenceInput
                    source="file"
                    reference="files"
                    perPage={100}
                >
                    <SelectInput optionText="title" />
                </ReferenceInput>
                <h4>OR</h4>
            </>
        )}
        <FileInput source="file" label="Choose a local file">
            <FileField source="file" title="title" />
        </FileInput>
    </div>
  );

  export default Upload;