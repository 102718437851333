import React  from 'react';
import { BooleanField, DateField, FunctionField, TextField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `offers/create`,
          state: { record: omitId(record) }
        }}/>);
};

const OffersList = (props) => (
    <ListGuesser {...props}>
      <TextField source="code" />
      <TextField source="amount" />
      <FunctionField
        source="percent"
        render={record => `${record.percent}%`}
      />
      <DateField source="createdAt" />
      <BooleanField source="enabled" />
      <CloneElement />
    </ListGuesser>
);

export default OffersList;