import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';

const CelebrityCreate = (props) => (
  <CreateGuesser {...props}>
    <ReferenceInput label="Language" source="language" reference="languages">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <InputGuesser source="name" />
    <ReferenceInput
      label="Background"
      source="background"
      reference="files"
      perPage={100000}
      filter={{ title: "Background (Celebrity)" }}
    >
      <AutocompleteInput optionText="title" shouldRenderSuggestions={(value) => value.trim().length >= 3} />
    </ReferenceInput>
    <ReferenceInput
      label="Avatar"
      source="avatar"
      reference="files"
      perPage={100000}
      filter={{ title: "Avatar (Celebrity)" }}
    >
      <AutocompleteInput optionText="title" shouldRenderSuggestions={(value) => value.trim().length >= 3} />
    </ReferenceInput>
    <ReferenceInput
      label="Product"
      source="product"
      reference="products"
      perPage={100000}
    >
      <AutocompleteInput optionText="title" shouldRenderSuggestions={(value) => value.trim().length >= 3} />
    </ReferenceInput>
  </CreateGuesser>
  );

export default CelebrityCreate;