import React  from 'react';
import { ReferenceField, TextField, ReferenceArrayField, SingleFieldList, ChipField, BooleanField, useRecordContext, DateField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";
import { Rating } from 'react-simple-star-rating'

const RatingValue = ({ source }) => {
  const record = useRecordContext();
  return (<Rating size={20} ratingValue={record.rating * 20} readonly />);
};

const ArtistList = (props) => (
    <ListGuesser {...props}>
      <ReferenceField label="Last name" source="user" reference="users">
        <TextField source="lastName" />
      </ReferenceField>
      <ReferenceField label="First name" source="user" reference="users">
        <TextField source="firstName" />
      </ReferenceField>
      <TextField source="status" />
      <><RatingValue source="rating" /></>
      <ReferenceArrayField
        label="Sub categories"
        reference="sub_categories"
        source="subCategories"
      >
        <SingleFieldList>
            <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="Languages"
        reference="languages"
        source="languages"
      >
        <SingleFieldList>
            <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField source="enabled" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </ListGuesser>
  );

export default ArtistList;