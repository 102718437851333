import React  from 'react';
import { TextField, ReferenceArrayField, Datagrid, ReferenceField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `group_step_formats/create`,
          state: { record: omitId(record) }
        }}/>);
};

const GroupStepFormatList = (props) => (
    <ListGuesser {...props}>
      <TextField source="title" />
      <ReferenceArrayField label="Group step format position" reference="group_step_format_positions" source="groupStepFormatPosition">
        <Datagrid>
          <TextField source="position" />
          <ReferenceField
            label="Step format"
            source="stepFormats"
            reference="step_formats"
          >
            <TextField source="title" />
          </ReferenceField>
        </Datagrid>
      </ReferenceArrayField>
      <CloneElement />
    </ListGuesser>
);

export default GroupStepFormatList;