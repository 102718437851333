import React  from 'react';
import { ReferenceField, TextField, BooleanField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CategoryList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="title" />
      <TextField source="slug" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Collection" source="collection" reference="collections">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="File" source="file" reference="files">
        <TextField source="title" />
      </ReferenceField>
      <BooleanField source="enabled" />
    </ListGuesser>
  );

export default CategoryList;