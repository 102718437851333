import React  from 'react';
import { ReferenceField, TextField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CelebrityList = (props) => (
    <ListGuesser {...props}>
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <ReferenceField label="Background" source="background" reference="files">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Avatar" source="avatar" reference="files">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Product" source="product" reference="products">
        <TextField source="title" />
      </ReferenceField>
    </ListGuesser>
  );

export default CelebrityList;