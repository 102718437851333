import React  from 'react';
import { ReferenceField, Datagrid, ReferenceArrayField, SingleFieldList, ChipField, TextField, CloneButton, BooleanField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `step_format_values/create`,
          state: { record: omitId(record) }
        }}/>);
};

const StepFormatValueList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="title" />

      <ReferenceField label="Price Rules" reference="price_rules" source="priceRules">
          <ReferenceArrayField
            label="Intl"
            source="priceIntl"
            reference="price_intls"
          >
            <Datagrid>
              <TextField source="price" />
              <ReferenceField
                  label="Currency"
                  source="currency"
                  reference="currencies"
              >
                <TextField source="code" />
              </ReferenceField>
            </Datagrid>
          </ReferenceArrayField>
      </ReferenceField>

      <TextField source="priceSale" />
      <TextField source="gelatoId" />
      <ReferenceArrayField
        label="Files"
        reference="files"
        source="images"
      >
        <SingleFieldList>
            <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField source="byDefault" />
      <TextField source="color" />
      <CloneElement />
    </ListGuesser>
);

export default StepFormatValueList;