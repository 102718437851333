import React  from 'react';
import { ReferenceField, TextField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `step_type_value_intls/create`,
          state: { record: omitId(record) }
        }}/>);
};

const StepTypeValueIntlList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="title" />
      <TextField source="placeholder" />
      <ReferenceField label="Language" source="language" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <CloneElement />
    </ListGuesser>
);

export default StepTypeValueIntlList;