import React  from 'react';
import { BooleanField, ReferenceField, ReferenceArrayField, Datagrid, TextField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `offers/create`,
          state: { record: omitId(record) }
        }}/>);
};

const ShippingMethodList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="code" />
      <TextField source="deadline" />
      <TextField source="drawing_delivery" />
      <ReferenceField label="Price Rules" reference="price_rules" source="priceRules">
          <ReferenceArrayField
            label="Intl"
            source="priceIntl"
            reference="price_intls"
          >
            <Datagrid>
              <TextField source="price" />
              <ReferenceField
                  label="Currency"
                  source="currency"
                  reference="currencies"
              >
                <TextField source="code" />
              </ReferenceField>
            </Datagrid>
          </ReferenceArrayField>
      </ReferenceField>
      <BooleanField source="enabled" />
      <CloneElement />
    </ListGuesser>
);

export default ShippingMethodList;