import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";

const TagEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
  </EditGuesser>
  );

export default TagEdit;