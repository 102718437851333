import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const GroupFaqPositionEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="position" />
    <ReferenceInput
      label="Faq"
      source="faq"
      reference="faqs"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
  </EditGuesser>
  );

export default GroupFaqPositionEdit;