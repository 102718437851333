import React  from 'react';
import { ReferenceField, TextField, BooleanField, ReferenceArrayField, SingleFieldList, ChipField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const SubCategoryList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="title" />
      <TextField source="slug" />
      <ReferenceField label="File" source="file" reference="files">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceArrayField
        label="Categories"
        reference="categories"
        source="categories"
      >
        <SingleFieldList>
            <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField source="enabled" />
      <BooleanField source="lastPosition" />
    </ListGuesser>
  );

export default SubCategoryList;