import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { useGetOne } from 'react-admin';

const GroupFaqName = ({ record }) => {
  const { data, loading } = useGetOne('faqs', record.faq);
  if (loading) { return "Loading..."; }
  if (data) {
    return `${record.position} / ${data.title}`;
  }

  return `${record.position}`;
};

const GroupFaqEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="title" />
    <ReferenceArrayInput
      label="Group faq position"
      reference="group_faq_positions"
      source="groupFaqPosition"
      perPage={1900}
    >
      <SelectArrayInput
        optionText={GroupFaqPositions => <GroupFaqName record={GroupFaqPositions} />}
      />
    </ReferenceArrayInput>
  </EditGuesser>
  );

export default GroupFaqEdit;