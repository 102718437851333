import React  from 'react';
import { ReferenceArrayField, SingleFieldList, ChipField, TextField, CloneButton } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const CloneElement = ({ record }) => {
  const omitId = ({ id, originId, uuid, ...rest }) => rest;
  delete record["@type"];
  delete record["@id"];

  return (<CloneButton to={{
          pathname: `tags/create`,
          state: { record: omitId(record) }
        }}/>);
};

const TagList = (props) => (
    <ListGuesser {...props}>
      <TextField source="name" />
      <TextField source="title" />
      <ReferenceArrayField
        label="Type values"
        reference="step_type_values"
        source="stepTypeValues"
      >
        <SingleFieldList>
            <ChipField source="value" />
        </SingleFieldList>
      </ReferenceArrayField>
      <CloneElement />
    </ListGuesser>
);

export default TagList;