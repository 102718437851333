import React  from 'react';
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, SelectInput } from 'react-admin';

const StepTypeValueIntlEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="title" />
    <InputGuesser source="placeholder" />
    <ReferenceInput label="Language" source="language" reference="languages">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </EditGuesser>
  );

export default StepTypeValueIntlEdit;