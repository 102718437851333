import React  from 'react';
import { ReferenceField, TextField, ReferenceArrayField, SingleFieldList, ChipField, FunctionField } from "react-admin";
import { ListGuesser } from "@api-platform/admin";

const entrypoint = "https://d1wqdzlxqamchi.cloudfront.net/"

const FilenameField = props => (
  <FunctionField 
    {...props}
    render={record => {
      var ext =  record.filePath.split('.').pop(); 

      if (ext === "jpg" || ext === "png" ||  ext === "jpeg" || ext === "webp") {
        return (
          <img
            alt={record.alt}
            src={`https://cdnserver.cartoonely.com/upload/w_200,h_200,q_40/${encodeURI(`${entrypoint}media/${record.filePath}`)}`}
            width={100}
          />
        )
      }
    }}
  />
);

const PortfolioList = (props) => (
    <ListGuesser {...props}>
      <FilenameField />
      <ReferenceField label="Artist" source="artist" reference="artists">
        <ReferenceField label="Artist" source="user" reference="users">
          <TextField source="firstName" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField label="Artist" source="artist" reference="artists">
        <ReferenceField label="Artist" source="user" reference="users">
          <TextField source="lastName" />
        </ReferenceField>
      </ReferenceField>
      <TextField source="title" />
      <TextField source="numberView" />
      <ReferenceField label="Category" source="category" reference="portfolio_categories">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="SubCategory" source="subCategory" reference="portfolio_sub_categories">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField
        label="Tags"
        reference="tags"
        source="tags"
      >
        <SingleFieldList>
            <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField label="File" source="file" reference="files">
        <TextField source="title" />
      </ReferenceField>
    </ListGuesser>
  );

export default PortfolioList;