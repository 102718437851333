import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const GroupStepFormatPositionCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="position" />
    <ReferenceInput
      label="Step format"
      source="stepFormats"
      reference="step_formats"
      perPage={1900}
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
  </CreateGuesser>
  );

export default GroupStepFormatPositionCreate;