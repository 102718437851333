import React  from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, SelectInput, AutocompleteInput } from 'react-admin';

const OrderCreate = (props) => (
  <CreateGuesser {...props}>
    <ReferenceInput
      source="user"
      reference="users"
      label="User"
      perPage={10000000}
    >
      <AutocompleteInput optionText={user => user ? `${user.lastName} ${user.firstName}` : "email"} />
    </ReferenceInput>
    <InputGuesser source="status" />
    <InputGuesser source="step" />
    <InputGuesser source="email" />
    <InputGuesser source="orderProduct" />
    <InputGuesser source="reference" />
    <InputGuesser source="quantity" />
    <InputGuesser source="totalPrice" />
    <InputGuesser source="priceSalesTaxes" />
    <ReferenceInput label="Currency" source="currency" reference="currencies">
      <SelectInput optionText="code" />
    </ReferenceInput>
    <ReferenceInput label="Tax" source="tax" reference="taxes">
      <SelectInput optionText="percent" />
    </ReferenceInput>
  </CreateGuesser>
  );

export default OrderCreate;